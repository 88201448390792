body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, main{
  background-color: var(--mainBackgroundColor);
}

.imageWrapper{
    position:relative;
    float: left;
}

.imageWrapper:before {
  content: '';
  position:absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 45% 40%,rgba(0,0,0,0) 0%, rgba(76,76,76,0.1) 35%,  rgba(76,76,76,1) 45%);
  z-index: 1;
}

#home-jobtitle{
  font-size: 1.5em;
  font-weight: bold;
  color: var(--mainTextColor);
}